<template>
  <div class="GroupListOld public_main_bgc public_css_module">
    <div class="w_d center" style="margin: 0 auto">
      <div class="bread" style="
          height: 49px;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
        ">
        <div class="bread_content" style="margin: 0 !important">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">教研组列表</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="bread_hengxian"></div> -->
      </div>

      <!-- 筛选 -->
      <div class="select">
        <div class="select_left">
          <div class="xueduan">
            <p>学段：</p>
            <div class="xueduan_block cursor" :class="sectionIndex == index ? 'active_block' : ''" v-for="(item, index) in sectionList" :key="index"
              @click="sectionChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan">
            <p>学科：</p>
            <div class="xueduan_block cursor" :class="subjectIndex == index ? 'active_block' : ''" v-for="(item, index) in subjectList" :key="index"
              @click="subjectChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan">
            <p>类型：</p>
            <div class="xueduan_block cursor" :class="typeIndex == index ? 'active_block' : ''" v-for="(item, index) in typeList" :key="index"
              @click="typeChange(index, item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="df dfb">
            <div class="sousuo-box">
              <p>查找：</p>
              <div class="box-right">
                <div class="sousuo">
                  <el-input placeholder="输入组名、学校名称" prefix-icon="el-icon-search" v-model.trim="searchText" @keyup.enter.native="searchButton()"></el-input>
                  <el-button type="primary" @click="searchButton('text')">搜索</el-button>
                </div>
                <!-- <div class="sousuo">
                <el-input
                  placeholder="输入访问码"
                  prefix-icon="el-icon-search"
                  v-model="searchCode"
                ></el-input>
                <el-button type="primary" @click="searchButton('code')"
                  >搜索</el-button
                >
              </div> -->
              </div>
            </div>
            <div v-if="false" class="sousuo_right pr20">
              <el-select v-model="teachingResearch.all_web_id" placeholder="区域选择" clearable size="small" style="width: 100px" @change="searchButton">
                <el-option v-for="item in area_option" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <!-- <div class="select_right">
          <div class="create cursor" @click="go">+ 创建教研组</div>
        </div> -->
      </div>
    </div>
    <!-- 中间和分页 -->
    <div class="CenterPage">
      <div class="main">
        <CommonEmpty v-if="!teachingResearchList.length" hVal="100%"></CommonEmpty>
        <el-card class="cursor main_block" shadow="hover" :body-style="{ padding: '0px' }" v-for="(item, index) in teachingResearchList" :key="index"
          @click.native="enterDetails(item.id)">
          <div class="recommend_block">
            <div class="images">
              <img :src="item.cover_url" />
            </div>
            <div class="word">
              <p class="word_one">
                {{ item.name }}
              </p>
              <div class="word_two">
                <span>{{ item.type_text }}</span>
                <span>{{ item.stage_name }}</span>
                <span>{{ item.subject_name }}</span>
              </div>
              <div class="word_four">
                <div class="ellipsis_1">组长: {{item.username || 0 }}</div>
                <div class="ellipsis_1">成员: {{item.user_count || 0 }}</div>
              </div>
              <div class="word_four">
                <div class="ellipsis_1">活动: {{item.activity_count || 0 }}</div>
                <div class="ellipsis_1">资源: {{item.result_count || 0 }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="pagin">
        <el-pagination v-if="teachingResearch.total" background :current-page="teachingResearch.page" :page-size="teachingResearch.limit"
          layout="prev, pager, next, jumper" :total="teachingResearch.total" @current-change="currentChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sectionList: [], //学段列表
      sectionIndex: 0, //学段选择索引
      subjectList: [{ id: "", name: "全部" }], //学科类别
      subjectIndex: 0, //学科选择索引
      typeList: [
        { id: "", name: "全部" },
        { id: 1, name: "校本教研组" },
        { id: 2, name: "其他教研组" },
      ], //类型列表
      typeIndex: 0, //类型选择索引
      searchText: "", //查找文本
      searchCode: "", //查找访问码
      teachingResearchList: [], //教研组列表
      teachingResearch: {
        //教研组参数
        limit: 18,
        page: 1,
        total: 0,
        search_stage_id: "",
        search_subject_id: "",
        search_type: "",
        search_input: "",
        visit_code: "",
        all_web_id: 2, // 区域选择,
      },
      area_option: [
        {
          value: 1,
          label: "优秀推荐",
        },
        {
          value: 2,
          label: "本区域",
        },
      ],
    };
  },
  created() {
    this.getSectionList();
    this.getTeachingResearchList();
  },
  methods: {
    //获取学段
    async getSectionList() {
      const res = await this.$axios.get("/stage/stage_list");
      let data = res.data.data;
      data.unshift({ id: "", name: "全部" });
      this.sectionList = data;
    },
    //获取学科
    async getSubjectList(id) {
      const res = await this.$axios.get("/subject/list", {
        params: { pharseid: id },
      });
      let data = res.data.data;
      data.forEach((item) => {
        item.id = item.subjectId;
        item.name = item.subjectName;
      });
      data.unshift({ id: "", name: "全部" });
      this.subjectList = data;
    },
    //学段选择
    sectionChange(index, id) {
      this.sectionIndex = index;
      if (id) {
        this.getSubjectList(id);
        this.teachingResearch.search_stage_id = id;
        this.teachingResearch.search_subject_id = "";
        this.subjectIndex = 0;
      } else {
        this.teachingResearch.search_stage_id = "";
        this.teachingResearch.search_subject_id = "";
        this.subjectIndex = 0;
        this.subjectList = [{ id: "", name: "全部" }];
      }
      this.teachingResearch.page = 1;
    },
    //学科选择
    subjectChange(index, id) {
      this.subjectIndex = index;
      this.teachingResearch.search_subject_id = id;
      this.teachingResearch.page = 1;
    },
    //类型选择
    typeChange(index, id) {
      this.typeIndex = index;
      this.teachingResearch.search_type = id;
      this.teachingResearch.page = 1;
    },
    //获取教研组列表
    async getTeachingResearchList() {
      const res = await this.$axios.get("/TeacherGroup/more_list", {
        params: this.teachingResearch,
      });
      const data = res.data.data;
      this.teachingResearchList = data.data;
      this.teachingResearch.total = data.total;
    },
    //搜索按钮
    searchButton(type) {
      this.teachingResearch.page = 1;
      this.teachingResearch.search_input = this.searchText;
      // if (type == "text") {
      // }
      // if (type == "code") {
      //   this.teachingResearch.visit_code = this.searchCode;
      // }
      this.getTeachingResearchList();
    },
    //进入详情
    enterDetails(id) {
      this.$router.push({ path: "/groupdetailshouye", query: { groupId: id } });
    },
    //当前页码改变
    currentChange(val) {
      this.teachingResearch.page = val;
    },
  },
  watch: {
    teachingResearch: {
      deep: true,
      handler() {
        this.getTeachingResearchList();
      },
    },
  },
};
</script>

<style scoped lang="less">
.GroupListOld {
  // display: flex;
  // justify-content: center;

  .center {
    margin-bottom: 60px;
    // border: 1px solid black;
    .select {
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      .select_left {
        flex: 1;
        overflow: hidden;
        .xueduan {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          p {
            color: #999999;
            margin-right: 20px;
          }
          .xueduan_block {
            height: 26px;
            display: inline-flex;
            align-items: center;
            margin-right: 20px;
            padding: 0 12px;
            font-size: 16px;
            color: #333;
          }
          .xueduan_block2 {
            // width: 72px;
            padding: 0px 16px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }
        .sousuo-box {
          display: flex;
          align-items: center;
          p {
            color: #999999;
            flex-shrink: 0;
            margin-right: 20px;
          }
          .box-right {
            flex: 1;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            .sousuo {
              width: 320px;
              display: flex;
              /deep/ .el-input__inner {
                // border-radius: 0px;
                border-radius: 4px 0px 0px 4px;
              }
              /deep/ .el-button {
                // border-radius: 0px;
                border-radius: 0px 4px 4px 0px;
              }
            }
          }
        }
      }
      .select_right {
        .create {
          width: 140px;
          height: 38px;
          background: #ff8201;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: 700;
        }
      }
    }
  }
  .CenterPage {
    width: 100%;
    padding: 24px 0;
    .main {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .main_block {
        margin-bottom: 20px;
        margin-right: 17px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .recommend_block {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          padding: 15px;
          width: 453px;
          height: 142px;
          display: flex;
          justify-content: space-between;
          // margin-right: 20px;

          .images {
            width: 168px;
            height: 112px;
            background: #5b3dc2;
            border-radius: 6px;
            img {
              width: 168px;
              height: 112px;
              border-radius: 6px;
            }
          }
          .word {
            width: 238px;
            // height: 102px;
            .word_one {
              // width: 305px;
              font-weight: 700;
              margin-bottom: 12px;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              p {
                width: 220px !important;
              }
            }
            .word_two {
              font-size: 14px;
              margin-bottom: 15px;
              color: #999999;
              span:nth-child(1) {
                margin-right: 40px;
              }
              span:nth-child(2) {
                margin-right: 40px;
              }
            }
            .word_four {
              color: #999999;
              font-size: 14px;
              display: flex;
              margin-bottom: 6px;
              div:nth-child(1) {
                flex: 1;
              }
              div:nth-child(2) {
                flex: 1;
                padding-left: 10px;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
  }
}

.sousuo_right {
  /deep/.el-input__inner {
    background-color: #f4fbff;
  }
}
</style>